a,
a:hover {
  color: inherit;
}

.relation-card:hover,
.relation-node:hover {
  cursor: pointer;
}

.select-work-card-staged {
  cursor: pointer;
}

.select-work-card-staged:hover,
.select-work-card-staged:focus {
  background-color: rgba(0, 0, 0, 0.12);
}

.current-work-node:hover,
.edge:hover {
  cursor: default;
}

.relation-card {
  border-left: 0.25rem solid;
}

.relation-lead {
  font-weight: bold;
  margin-right: 0.25rem;
}

#navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.dropdown-item .dropdown-item-bibliography {
  white-space: normal;
  font-size: 0.8rem;
}

.paginated-results-item {
  cursor: pointer;
}

#graph {
  position: -webkit-sticky;
  position: sticky;
  top: 72px;
}
